import React, { useContext } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";

import TextArea from "@vahak/core-ui/dist/components/TextArea";
import Button from "@vahak/core-ui/dist/components/Button/Button";
import RadioGroup from "@vahak/core-ui/dist/components/RadioGroup/RadioGroup";
import Typography from "@vahak/core-ui/dist/components/Typography/Typography";
import { toast } from "@vahak/core/dist/components/toast/toast";
import Flex from "@vahak/core-ui/dist/layout/Flex";

// Styles
import Styles from "./CallbackRequestForm.module.scss";
import FlatInput from "@vahak/core/dist/components/flat-input/FlatInput";
import { AppContext } from "@vahak/core/dist/app-context";
import { MarketType } from "@vahak/core/dist/custom-types/market-type";

import { useCallMeBackRequest } from "@vahak/core/dist/_services/callMeBack.service";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { generateFormFieldNames } from "../../../../utils";
import { onChangeRadioGroupType } from "@vahak/core-ui/dist/components/RadioGroup/RadioGroup.types";
import { phoneRegExp } from "@vahak/core/dist/constants/regex";

interface CallbackRequestFormProps {
    type?: MarketType;
    onSubmit: (reason?: string, reasonCategory?: string) => void;
    onClose: () => void;
    sourceScreen: string;
}

enum CallBackReason {
    LOAD_HELP = "How to book a load",
    LORRY_HELP = "How to book a lorry",
    OTHER = "Other"
}

const reason = Object.values(CallBackReason).map((key) => ({
    label: key,
    value: key
}));

const CallbackRequestForm = ({ onSubmit, onClose, sourceScreen = "" }: CallbackRequestFormProps) => {
    const { phoneNum, userId } = useContext(AppContext);
    const formInit = {
        phone: String(!!userId ? phoneNum : ""),
        reason: "",
        reasonText: ""
    };
    const formFieldNames = generateFormFieldNames(formInit);

    const { mutate: callMeBack } = useCallMeBackRequest();

    const formik = useFormik({
        initialValues: formInit,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: () => {},
        validationSchema: Yup.object({
            [formFieldNames.reasonText]: Yup.string().required(),
            [formFieldNames.phone]: Yup.string().required().matches(phoneRegExp)
        })
    });

    const handleReasonChange: onChangeRadioGroupType = (e) => {
        formik.setFieldValue(formFieldNames.reason, e.target.value);

        const needReasonText = e.target.value !== CallBackReason.OTHER;
        formik.setFieldValue(formFieldNames.reasonText, needReasonText ? e.target.value : "");
    };

    const onChangeInput = (e: any) => {
        const value = e.target.value;
        if (e.target.name === formFieldNames.phone) {
            formik.setFieldValue(e.target.name, value.slice(0, 10));
        } else {
            formik.setFieldValue(e.target.name, value);
        }
    };

    const handleCallMeBackRequest = () => {
        const { phone, reasonText, reason } = formik.values;
        callMeBack(
            {
                ticket_type: 10,
                call_me_back_info: {
                    description: `Called from ${sourceScreen}`,
                    phone_number: `+91${phone}`,
                    reason: reasonText
                }
            },
            {
                onSuccess: (response) => {
                    toast.success(response?.data?.message ?? "");
                    onSubmit(reasonText, reason);
                },
                onError: (error: any) => {
                    toast.error(error?.message ?? "Something went wrong!");
                }
            }
        );
    };

    return (
        <div className={classNames(Styles.mainWrapper)}>
            <Typography size="m" color={COLORS.GREY_600}>
                You will receive a Call back from our Customer Care Executives within 24 hours
            </Typography>

            {!!formInit.phone ? (
                <Typography>
                    Phone Number :&nbsp;
                    <Typography weight="bold">+91-{String(formInit.phone).replace("+91", "")} </Typography>
                </Typography>
            ) : (
                <div className={classNames(Styles.inputWrapper)}>
                    <FlatInput
                        name={formFieldNames.phone}
                        type="number"
                        value={formik.values[formFieldNames.phone]}
                        onChange={onChangeInput}
                        labelText="Enter your mobile number"
                        valuePrefix="+91 - "
                    />
                </div>
            )}
            <Flex flexDirection="column">
                <RadioGroup
                    groupLabel="Tell us your reason to request the Call back"
                    value={formik.values[formFieldNames.reason]}
                    name={formFieldNames.reason}
                    option={reason}
                    onChange={handleReasonChange}
                    direction="row"
                    gap={5}
                    wrap
                    required
                    id="callback-reason"
                />

                {formik.values[formFieldNames.reason] === CallBackReason.OTHER ? (
                    <TextArea
                        value={formik.values[formFieldNames.reasonText]}
                        name={formFieldNames.reasonText}
                        onChange={onChangeInput}
                        placeholder={`Enter reason`}
                    />
                ) : null}
            </Flex>

            <Flex justifyContent="flex-end" gap={20} margin="20px 0">
                <Button color="secondary" fillType="outlined" width={120} onClick={onClose}>
                    Cancel
                </Button>
                <Button width={120} onClick={handleCallMeBackRequest} disabled={!formik.isValid}>
                    Confirm
                </Button>
            </Flex>
        </div>
    );
};

export default CallbackRequestForm;
