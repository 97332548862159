import React from "react";
import CallbackRequest from "../components/misc/callback-request/CallbackRequest";
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks/useToggleModalWithLocalState";

interface IUseRequestCallBack {
    src?: string;
    trackOnClose?: () => void;
    trackOnCancelled?: () => void;
    trackOnSubmit?: (reason?: string) => void;
}

const useRequestCallBack = ({ src = "-", trackOnClose, trackOnCancelled, trackOnSubmit }: IUseRequestCallBack = {}) => {
    const {
        openModal: openCallBackReqModal,
        closeModal: closeCallBackReqModal,
        isModalOpen
    } = useToggleModalWithLocalState(false);

    const CallBackForm = (
        <>
            {isModalOpen ? (
                <CallbackRequest
                    isCallbackRequestPopupVisible={isModalOpen}
                    toggleCallBackRequestModal={closeCallBackReqModal}
                    onSubmit={(reason) => {
                        closeCallBackReqModal();
                        trackOnSubmit?.(reason);
                    }}
                    sourceScreen={src}
                    trackOnCancelled={trackOnCancelled}
                    trackOnClose={trackOnClose}
                />
            ) : null}
        </>
    );

    return {
        CallBackForm,
        openCallBackReqModal
    };
};

export default useRequestCallBack;
