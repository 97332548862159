import classNames from "classnames";
import React, { ReactNode, useContext } from "react";
import { MarketType } from "@vahak/core/dist/custom-types/market-type";
import A from "@vahak/core/dist/components/app-anchor/A";
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";

import CallbackRequestForm from "./callback-request-form/CallbackRequestForm";

// Style
import Styles from "./CallbackRequest.module.scss";

// Interface
interface CallbackRequestProps {
    toggleButton?: ReactNode;
    className?: string;
    type?: MarketType;
    bottomSheetHeading?: ReactNode;
    modalHeading?: ReactNode;
    isCallbackRequestPopupVisible: boolean;
    toggleCallBackRequestModal: () => void;
    onSubmit: (reason?: string, reasonType?: string) => void;
    sourceScreen: string;

    trackOnClose?: () => void;
    trackOnCancelled?: () => void;
}
const CallbackRequest = ({
    toggleButton,
    className,
    type,
    isCallbackRequestPopupVisible,
    toggleCallBackRequestModal,
    onSubmit,
    sourceScreen,
    trackOnClose,
    trackOnCancelled
}: CallbackRequestProps) => {
    return (
        <div>
            {toggleButton && (
                <A className={classNames(Styles.toggleButton, className)} onClick={() => toggleCallBackRequestModal()}>
                    {toggleButton}
                </A>
            )}
            {isCallbackRequestPopupVisible ? (
                <ModalOrBottomSheetWrapper
                    isOpen={isCallbackRequestPopupVisible}
                    onToggle={() => {
                        toggleCallBackRequestModal();
                        trackOnClose?.();
                    }}
                    titleText="Request a Call back"
                    modalSize="fit"
                >
                    <CallbackRequestForm
                        onSubmit={onSubmit}
                        type={type}
                        onClose={() => {
                            toggleCallBackRequestModal();
                            trackOnCancelled?.();
                        }}
                        sourceScreen={sourceScreen}
                    />
                </ModalOrBottomSheetWrapper>
            ) : (
                <></>
            )}
        </div>
    );
};

export default CallbackRequest;
